<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
      <v-btn tile v-if="permiteAcao($route, 'add')" :color="variables.colorPrimary" dark class="mb-2 modal-cadastro-btn-cadastrar" @click="openModal">Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-form>
        <v-card-title class="modal-cadastro-titulo">
          {{ isEdit ? 'Editar grupos' : 'Cadastrar grupo' }}
        </v-card-title>

        <v-card-text class="modal-cadastro-form">
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field :error-messages="nameErrors" v-model="item.name" label="Name:" @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()" /></v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'

export default {
  name: 'BrModalCadastroRoles',
  mixins: [validationMixin],
  data: () => ({
    activeModal: false,
    loading: false
  }),
  computed: {
    ...mapGetters('grupos', ['item']),
    ...mapGetters('roles', ['permiteAcao']),

    nameErrors () {
      const errors = []
      if (!this.$v.item.name.$dirty) return errors
      !this.$v.item.name.required && errors.push('Nome é obrigatório.')
      return errors
    },

    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    variables: () => variables,
  },
  mounted () {
    Events.$on('cadastro::openModalCadastro', () => {
      this.activeModal = true
    })
  },
  methods: {
    ...mapActions('grupos', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem']),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },

    submit () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.loading = true

      if (this.item.id) {
        const dados = {
          id: this.item.id,
          name: this.item.name
        }

        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.msg
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      } else {
        const dados = {
          name: this.item.name,
        }

        this.cadastrarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.msg
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }

    },
  },

  validations () {
    return  {
      item: {
        name: { required }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/formularios';
  @import '../../../../../assets/styles/components/modal-cadastro';
</style>